const db = {
    "name": "@kevincxy",
    "imgSrc": "/dp.jpeg",
    "bgSrc": "/bg.jpeg",
    "links": [
        {
            "name": "IconsPro",
            "href": "https://iconspro.pages.dev/"
        },
        {
            "name": "JSON Parser",
            "href": "https://jsonparser.pages.dev/"
        },
        {
            "name": "URL Decoder",
            "href": "https://urldecoder.pages.dev/"
        },
        {
            "name": "Shorty Link Shortener",
            "href": "https://shortylink.pages.dev/"
        },
        {
            "name": "WhatsApp Anyone",
            "href": "https://whatsapp-any.vercel.app/"
        },
        {
            "name": "Custom Linktree (this!)",
            "href": "https://github.com/kev-inc/custom-linktree"
        }
    ]
}

export default db
